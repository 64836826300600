<template>
  <div class="body-2 grey--text text--darken-3">
    <span class="font-weight-bold"> Pares relacionados: </span>
    <template v-if="pares.length > 0">
      <ul v-for="par in pares" :key="par.id" class="body-2">
        <li class="mt-3">
          {{ par.name }}
        </li>
      </ul>
    </template>
    <template v-else>
      <span> Nenhum par relacionado. </span>
    </template>
  </div>
</template>

<script>
export default {
  name: "ParesSimpleList",
  props: {
    pares: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped></style>
